.leaderboard {
  display: flex;
  justify-content: center;
  background-size: cover;

  h2 {
    text-align: center;
  }

  .center {
    text-align: center;
  }

  .header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-bottom: 1px solid white;
    margin-bottom: 15px;
  }

  .leaderboard-inner {
    width: 60%;
    background-color: rgba(233, 233, 233, 0.95);
    padding: 10px 30px;
    height: 70%;
    margin: auto;
    border-radius: 16px;
    overflow: hidden;
  }

  .outer-podium-wrapper {
    display: flex;
    justify-content: center;
    align-items: stretch;
    margin: 10px 0;
  }

  .podium-wrapper {
    width: 100px;
    height: 60px;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    align-items: center;

    .podium {
      color: white !important;
      border-radius: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
    }

    .winner {
      background-color: blue;
      width: 42px;
      height: 42px;
      font-size: 22px;
    }

    .second-place {
      background-color: hotpink;
      width: 35px;
      height: 35px;
      font-size: 18px;
    }

    .third-place {
      background-color: orange;
      width: 28px;
      height: 28px;
    }
  }

  table {
    font-family: arial, sans-serif;
    border-collapse: collapse;
    width: 100%;
  }

  tr {
    background-color: transparent !important;
  }

  td {
    background-color: transparent !important;
  }

  td,
  th {
    border-bottom: 1px solid rgba(255, 255, 255, 0.4);
    text-align: left;
    padding: 8px;
  }
}
