.dashboard {
  display: flex;
  justify-content: center;
  background-image: url("https://images.pexels.com/photos/4523021/pexels-photo-4523021.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=2");
  background-size: cover;

  h2 {
    text-align: center;
  }

  .header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-bottom: 1px solid white;
    margin-bottom: 15px;
  }

  .dashboard-inner {
    width: 60%;
    background-color: rgba(233, 233, 233, 0.95);
    padding: 10px 30px;
    height: 70%;
    margin: auto;
    border-radius: 16px;
    overflow: hidden;
    justify-content: space-evenly;
  }

  .card {
    background-color: white;
    width: 47%;
    border-radius: 16px;
    overflow: auto;
    text-align: center;

    .card-header {
      width: 100%;
      background: #1840e3;
      padding: 10px;
      border-radius: 16px 16px 0 0;
      color: white;
    }

    p {
      color: #1840e3;
      font-size: 36px;
      margin: 10px;
    }
  }
}
